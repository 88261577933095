
import { defineComponent } from 'vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import getTranslatedBodyType from '@/filters/getTranslatedBodyType'
export default defineComponent({
  emits: ['onCarBodyCheck'],
  components: {
    Checkbox
  },
  props: {
    checkboxesValues: {
      type: Object,
      default: () => ({})
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const onCarBodyCheck = ($event) => {
      emit('onCarBodyCheck', $event)
    }
    return { onCarBodyCheck, getTranslatedBodyType }
  }
})
