
import { defineComponent, reactive } from 'vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
export default defineComponent({
  emits: [
    'onCheck',
    'selectElectricOption',
    'selectHybridOption',
    'selectMileageOption',
    'selectDurationOption',
    'selectMandatoryOptions'
  ],
  props: {
    checkboxesValues: {
      type: Object,
      default: () => ({})
    },
    selectedElectricOption: {
      type: [String, Number],
      default: ''
    },
    selectedHybridOption: {
      type: [String, Number],
      default: ''
    },
    isMileageDuration: {
      type: Boolean,
      default: false
    },
    selectedMileageOption: {
      type: Array,
      default: () => []
    },
    selectedDurationOption: {
      type: Array,
      default: () => []
    },
    chargingMandatoryOptions: {
      type: Array,
      default: () => []
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const ElectricRange = reactive([
      '< 100 km',
      '100 km',
      '200 km',
      '300 km',
      '400 km',
      '500 km',
      '600 km',
      '700 km',
      '800 km',
      '900 km',
      '> 1000 km'
    ])
    const HybridRange = reactive(['< 50 km', '50 km', '75 km', '100 km', '125 km'])
    const MileageRange = reactive(['20000 km', '25000 km', '30000 km', '35000 km', '40000 km', '45000 km'])
    const DurationRange = reactive(['36', '48', '60'])
    const convertToNumber = (selectedOption) => {
      return parseInt(selectedOption.replace(/[kmmonths<>]/g, ''))
    }
    const getCarPolicyRange = (storedRange, rangeArray) => {
      let range
      if (sessionStorage.getItem(storedRange)) {
        const carPolicyRange = parseInt(JSON.parse(sessionStorage.getItem(storedRange) || ''))
        const getNumbers = rangeArray.map((value) => convertToNumber(value))
        getNumbers[0] = 0
        const getIndex = getNumbers.indexOf(carPolicyRange)
        range = rangeArray.slice(getIndex)
      } else {
        range = rangeArray
      }
      return range
    }
    const onCheck = ($event, title, index) => {
      emit('onCheck', { item: $event, title: title, index: index })
    }
    const selectElectricOption = (item) => {
      emit('selectElectricOption', item)
    }
    const selectHybridOption = (item) => {
      emit('selectHybridOption', item)
    }
    const selectMileageOption = (item) => {
      emit('selectMileageOption', item)
    }
    const selectDurationOption = (item) => {
      emit('selectDurationOption', item)
    }
    return {
      ElectricRange,
      HybridRange,
      onCheck,
      selectElectricOption,
      selectHybridOption,
      MileageRange,
      DurationRange,
      selectMileageOption,
      selectDurationOption,
      getCarPolicyRange
    }
  },
  components: {
    Checkbox,
    Dropdown
  }
})
